<template>
    <div class="relative inline-block text-left w-full h-[30px]">
        <!-- Button -->
        <button
            :style="{ width: width, height: height }"
            class="w-full h-1/3 flex justify-between items-center px-4 py-3 text-sm font-manrope text-[#4C535F] border border-[#E0E4EC] bg-[#F7F9FC] rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none transition-all duration-200"
            @click="toggleDropdown"
            type="button"
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
        >
            {{ modelValue || firstItem }}
            <svg class="h-[10%] w-[10%] text-[#8D98AA] transition-transform duration-200" :class="{ 'rotate-180': showMenu }" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                    fill-rule="evenodd"
                    d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                    clip-rule="evenodd"
                />
            </svg>
        </button>

        <!-- Dropdown Menu -->
        <transition
            enter-active-class="transition ease-out duration-150"
            enter-from-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-95"
        >
            <div
                v-if="showMenu"
                class="absolute z-10 mt-2 w-full bg-white shadow-lg rounded-lg ring-1 ring-gray-200 focus:outline-none border border-[#E0E4EC]"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabindex="-1"
            >
                <ul class="py-1">
                    <li
                        v-for="(item, index) in items"
                        :key="index"
                        @click="selectItem(item)"
                        class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 transition-all duration-150"
                        role="menuitem"
                        tabindex="-1"
                    >
                        {{ item }}
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: String, // v-model binding
        firstItem: {
            type: String,
            default: "Select an option",
        },
        items: {
            type: Array,
            default: () => ["Option 1", "Option 2", "Option 3"],
        },
        width: {
            type: String,
            default: "100%", // Ensuring full width for better responsiveness
        },
        height: {
            type: String,
            default: "auto",
        },
    },
    data() {
        return {
            showMenu: false,
        };
    },
    methods: {
        toggleDropdown() {
            this.showMenu = !this.showMenu;
        },
        selectItem(item) {
            this.$emit("update:modelValue", item); // Emit selected item for v-model
            this.showMenu = false;
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.showMenu = false;
            }
        },
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    },
};
</script>
