<template>
    <div class="subscription-modal" v-if="this.Store.showSubscriptions">
        <div class="subscriptionContainer">
            <div class="left-container">
                <div class="content">
                    <h1>Send More<br />Assessments!</h1>
                    <ul>
                        <li>_ Psychometric Tests,</li>
                        <li>_ Soft Skills Tests,</li>
                        <li>_ Hard Skills Tests,</li>
                        <li>_ Personality Test,</li>
                        <li>_ Data Reporting,</li>
                        <li>_ And Much More.</li>
                    </ul>
                    <h3>Discover More about our Plans</h3>
                </div>
                <div class="circle circle_1"></div>
                <div class="circle circle_2"></div>
            </div>
            <div class="right-container">
                <div class="closeBtn">
                    <button class="nav-btn trash-btn" @click="this.Store.toggleSubscriptions">
                        <!-- <img loading="lazy"  decoding="async" src="@/assets/Images/icons/close-icon.svg" alt="close-icon" /> -->
                        <font-awesome-icon :icon="['fas', 'xmark']" class="nav-icon trash-icon" />
                    </button>
                </div>
                <h5>Choose Your Subscription:</h5>
                <div class="saving-container">
                    <div class="save-span"><span>Save 15%</span> on monthly plan!</div>
                    <div class="time-container">
                        <button class="monthly">Monthly</button>
                        <button class="yearly">Yearly</button>
                    </div>
                </div>
                <div class="level-container">
                    <div class="level-header">
                        <span class="level-span">Basic</span>
                        <span class="price-span">$99/mo</span>
                    </div>
                    <ul>
                        <li><span>✓</span> 2 User</li>
                        <li><span>✓</span> 50 Credit</li>
                        <li><span>✓</span> Access to all 298+ Tests</li>
                        <li><span>✓</span> Talent Marketplace - Limited</li>
                    </ul>
                </div>
                <div class="level-container">
                    <div class="level-header">
                        <span class="level-span">Pro</span>
                        <span class="price-span">$195/mo</span>
                    </div>
                    <ul>
                        <li><span>✓</span> 3 Users</li>
                        <li><span>✓</span> 300 Credit</li>
                        <li><span>✓</span> Access to all 298+ Tests</li>
                        <li><span>✓</span> Talent Marketplace - Limited</li>
                    </ul>
                </div>
                <div class="level-container">
                    <div class="level-header">
                        <span class="level-span">Ultimate</span>
                        <span class="price-span">$295/mo</span>
                    </div>
                    <ul>
                        <li><span>✓</span> 10+ Users</li>
                        <li><span>✓</span> Unlimited Credit</li>
                        <li><span>✓</span> Talent Marketplace - Unlimited</li>
                        <li><span>✓</span> Hiring Outsourcing Services - Paid Services</li>
                    </ul>
                </div>
                <button class="continue-button">Continue</button>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "../store/index.js";
export default {
    name: "SubscriptionComponent",
    data() {
        return {};
    },
    props: {
        show: Boolean,
        togglePlans: Function,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
};
</script>

<style scoped lang="scss">
.subscription-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.199);
    overflow: hidden;
    z-index: 30;
}

.subscriptionContainer {
    width: 70%;
    height: 670px;
    display: flex;
    border-radius: 20px;
    overflow: hidden;
}

.left-container {
    width: 50%;
    height: 100%;
    background: linear-gradient(180deg, #2196f3 0%, #02298a 84.79%, #021b79 100%);
    position: relative;
    overflow: hidden;
}

.right-container {
    height: 100%;
    width: 50%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 0;
}

.closeBtn {
    width: 80%;
    display: flex;
    justify-content: flex-end;

    > :first-child {
        width: fit-content;
        cursor: pointer;
    }
}

.right-container h5 {
    color: #333;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    /* margin-left: 100px; */
    width: 100%;
    height: 75%;
    padding: 5% 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    /* height: auto; */
}

.content h1 {
    color: #fff;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    text-align: left;
}

.content h3 {
    color: #fff;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;
    margin-left: -15px;
    margin-top: 50px;
}

.content ul {
    list-style-type: none;
    margin-left: 10px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.content ul li {
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.circle {
    width: 357px;
    height: 357px;
    flex-shrink: 0;
    border-radius: 557px;
    border: 1px solid #fff;
    overflow: hidden;
}

.circle_1 {
    position: absolute;
    left: -20%;
    bottom: -25%;
}

.circle_2 {
    position: absolute;
    left: -10%;
    bottom: -27%;
}

.saving-container {
    display: flex;
    align-items: center;
}

.time-container {
    display: inline-flex;
    padding: 4px;
    align-items: flex-start;
    border-radius: 100px;
    border: 1px solid var(--sementics-color-border-muted, #e4e4e7);
    background: var(--sementics-color-primary-on-default, #fff);
    margin-left: 10px;
}

.monthly {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #2196f3;
    color: var(--sementics-color-primary-on-default, #fff);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    /* 16.1px */
}

.yearly {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    color: var(--sementics-color-fg-disabled, #a1a1aa);
    background: #fff;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    /* 16.1px */
}

button {
    border: none;
    cursor: pointer;
    transition: 0.3s;
}

button:hover {
    opacity: 0.8;
}

.continue-button {
    display: flex;
    width: 72%;
    height: 50px;
    padding: 18px 26px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #2196f3;
    color: var(--White, #fff);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 20px;
}

.level-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: auto;
    flex-shrink: 0;
    border-radius: 20px;
    border: 0.9px solid #ccd7ec;
    background: #fff;
    margin-top: 10px;
    padding: 5px;
    cursor: pointer;
}

.level-container:hover {
    border: 0.9px solid #0f172a;
}

.level-container ul {
    list-style-type: none;
    width: 100%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5%;
}

.level-container ul li {
    color: #0f172a;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.level-container ul span {
    color: #2196f3;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.level-header {
    position: relative;
    margin-bottom: 5px;
    padding-top: 10px;
}

.level-header span {
    color: #0f172a;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.level-span {
    position: absolute;
    left: 10px;
}

.price-span {
    font-size: 40px;
    position: absolute;
    right: 10px;
}

.save-span {
    color: #2196f3;
    /* sementic.type/label/s */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
}

.save-span span {
    color: #2196f3;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    /* 15px */
}

.trash-icon {
    color: red;
    border: 2px solid #e4e4e7;
    padding: 10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;

    &:hover {
        border: 2px solid red;
    }
}

// .trash-btn:hover {
//     border: 2px solid red;
// }

.trash-btn:hover + .trash-icon {
    color: red;
}

.nav-btn {
    padding-top: 2px;
}
</style>
