<template>
    <div class="row">
        <img loading="lazy" decoding="async" class="profile-image" src="@/assets/Images/icons/account.svg" alt="cadidate-profile" />
        <div class="info">
            <span class="candidate-name">Alex Manda</span> <br />
            <span class="timestamp">Today</span>
        </div>
        <div class="score" :class="scoreAccepted() ? 'aquired' : 'declined'">86%</div>
    </div>
</template>

<script>
export default {
    name: "RacentCandidateRow",
    methods: {
        scoreAccepted: function () {
            return true;
        },
    },
};
</script>

<style scoped>
.row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.profile-image {
    margin-right: 16px;
}

.candidate-name {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 14px;
    color: #1b2559;
}

.timestamp {
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 12px;
    color: #a3aed0;
}

.score {
    margin-left: auto;
    justify-self: end;
    padding: 4px 8px;
    border-radius: 7px;
    color: #05cd99;
}

.aquired {
    background: rgba(5, 205, 153, 0.1);
    color: #05cd99;
}

.declined {
    background: rgba(238, 93, 80, 0.1);
    color: #ee5d50;
}
</style>
