<template>
    <div class="progress-bar-container">
        <div class="label-container">
            <span class="label">{{ label }}</span>
            <span class="percentage">{{ percentage }}%</span>
        </div>
        <div class="progress-bar">
            <div class="progress-fill" style="{ width: `${percentage}%` }"></div>
        </div>
    </div>
</template>

<script setup>
defineProps({
    label: {
        type: String,
        required: true,
    },
    percentage: {
        type: Number,
        required: true,
        validator: (value) => value >= 0 && value <= 100,
    },
});
</script>

<style scoped>
.progress-bar-container {
    margin: 1rem 0;
}

.label-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    color: #34495e;
}

.progress-bar {
    height: 8px;
    background: #ecf0f1;
    border-radius: 4px;
    overflow: hidden;
}

.progress-fill {
    height: 100%;
    background: #3498db;
    border-radius: 4px;
    transition: width 0.5s ease-in-out;
}
</style>
