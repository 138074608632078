<template>
    <aside v-if="this.Store.getisLogged == 'user'" class="sidebar" :class="{ active_style: !SidebarViewOpen }">
        <div class="top-buttons" :class="!SidebarViewOpen ? 'flex-col gap-4' : ''">
            <div>
                <div class="w-full" style="display: flex; justify-content: space-between">
                    <button class="nav-btn btn-scale" @click="toggleShowCompanies" :class="{ hideText: !SidebarViewOpen }">
                        <font-awesome-icon :icon="['fas', 'ellipsis']" class="nav-icon" />
                    </button>
                    <button class="nav-btn logo-btn" @click="this.toggleSideBarView">
                        <font-awesome-icon :icon="['fas', 'bars']" class="nav-icon logo-icon" />
                    </button>
                </div>
                <div v-if="showCompanies" class="absolute w-full h-full top-0 left-0" style="z-index: 5" @click="toggleShowCompanies"></div>
                <div class="relative">
                    <div class="absolute left-[0] top-[100%] flex flex-col swCompany" style="z-index: 15" v-if="showCompanies">
                        <span class="p-2" v-for="(company, index) in companies" :key="index" @click="switchCompany(company)">{{ company }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="profile" v-show="SidebarViewOpen">
            <img loading="lazy" decoding="async" v-if="this.Store?.companyLogo" :src="getImgUrl(this.Store?.companyLogo)" height="150" width="150" alt="user" />
            <span v-else class="w-28 h-28 flex justify-center items-center rounded-full bg-NeonBlue text-white font-bold text-5xl shadow-[0_0_6px_1px_rgba(0,0,0,.15)] text-center">{{
                companyInitials
            }}</span>
            <div class="greeting">Hello {{ this.Store.company_name }}</div>
        </div>

        <ul class="nav-links" :class="{ centerIcons: !SidebarViewOpen }">
            <li>
                <span class="navTitle" :class="{ hideText: !SidebarViewOpen }">{{ $t("Assessments") }}</span>
                <router-link
                    to="/dashboard"
                    @click="selectTab(0)"
                    class="hover"
                    :class="`w-full gap-4 p-2 text-[#7d8fb3] flex items-center font-semibold  text-lg ${this.$route.path == '/dashboard' ? 'active ' : ''}`"
                >
                    <svg class="dashboard-img" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_216_5517)">
                            <path
                                :class="`${this.$route.path == '/dashboard' ? 'active-path-dashboard ' : ''}`"
                                d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z"
                                stroke="#535a7e"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_216_5517">
                                <rect width="24" height="24" fill="#535a7e" />
                            </clipPath>
                        </defs>
                    </svg>

                    <!-- <font-awesome-icon :icon="['fas', 'chart-line']" class="fa-icon" /> -->
                    <span :class="{ hideText: !SidebarViewOpen }">{{ $t("Projects") }}</span>
                </router-link>
            </li>
            <li>
                <router-link
                    to="/library"
                    @click="selectTab(1)"
                    class="hover"
                    :class="`w-full gap-4 p-2 text-[#7d8fb3] flex items-center font-semibold  text-lg ${this.$route.path == '/library' ? 'active ' : ''}`"
                >
                    <svg class="library-img" width="25" height="20" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            :class="`${this.$route.path == '/library' ? 'active-path-library ' : ''}`"
                            d="M32.0827 24.4126V6.81055C32.0827 5.06055 30.6535 3.76263 28.9181 3.90847H28.8306C25.7681 4.17097 21.116 5.73138 18.5202 7.36472L18.2723 7.52513C17.8493 7.78763 17.1493 7.78763 16.7264 7.52513L16.3618 7.30638C13.766 5.68763 9.12851 4.1418 6.06602 3.89388C4.3306 3.74805 2.91602 5.06055 2.91602 6.79597V24.4126C2.91602 25.8126 4.05352 27.1251 5.45352 27.3001L5.87643 27.3585C9.04102 27.7814 13.9264 29.3856 16.7264 30.9168L16.7848 30.946C17.1785 31.1647 17.8056 31.1647 18.1848 30.946C20.9848 29.4001 25.8848 27.7814 29.0639 27.3585L29.5452 27.3001C30.9452 27.1251 32.0827 25.8126 32.0827 24.4126Z"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            :class="`${this.$route.path == '/library' ? 'active-path-library ' : ''}`"
                            d="M17.5 8.00586V29.8809"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            :class="`${this.$route.path == '/library' ? 'active-path-library ' : ''}`"
                            d="M11.3027 12.3809H8.02148"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            :class="`${this.$route.path == '/library' ? 'active-path-library ' : ''}`"
                            d="M12.3965 16.7559H8.02148"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <!-- <font-awesome-icon :icon="['fas', 'chart-line']" class="fa-icon" /> -->

                    <span :class="{ hideText: !SidebarViewOpen }">{{ $t("Library") }}</span>
                </router-link>
            </li>
            <li style="margin-top: 20px">
                <span class="navTitle" :class="{ hideText: !SidebarViewOpen }">{{ $t("Insights") }}</span>
                <router-link
                    to="/activity"
                    @click="selectTab(2)"
                    class="hover"
                    :class="`w-full gap-4 p-2 text-[#7d8fb3] flex items-center font-semibold  text-lg ${this.$route.path == '/activity' ? 'active ' : ''}`"
                >
                    <svg class="activity-img" width="25" height="20" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            :class="`${this.$route.path == '/activity' ? 'active-path-activity ' : ''}`"
                            d="M13.1243 32.0837H21.8743C29.166 32.0837 32.0827 29.167 32.0827 21.8753V13.1253C32.0827 5.83366 29.166 2.91699 21.8743 2.91699H13.1243C5.83268 2.91699 2.91602 5.83366 2.91602 13.1253V21.8753C2.91602 29.167 5.83268 32.0837 13.1243 32.0837Z"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            :class="`${this.$route.path == '/activity' ? 'active-path-activity ' : ''}`"
                            d="M10.6895 21.1316L14.1603 16.6254C14.6561 15.9837 15.5749 15.8671 16.2165 16.3629L18.8853 18.4629C19.527 18.9587 20.4457 18.8421 20.9415 18.215L24.3103 13.8691"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>

                    <!-- <font-awesome-icon :icon="['fas', 'chart-line']" class="fa-icon" /> -->
                    <span :class="{ hideText: !SidebarViewOpen }">{{ $t("Activity") }}</span>
                </router-link>
            </li>
            <li>
                <router-link
                    class="hover"
                    to="/candidates"
                    :class="`w-full gap-4 p-2 text-[#7d8fb3] flex items-center font-semibold  text-lg ${this.$route.path == '/candidates' ? 'active ' : ''}`"
                    @click="selectTab(3)"
                >
                    <svg class="talent-img" xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 87 87" fill="none">
                        <path
                            :class="`${this.$route.path == '/candidates' ? 'active-path-talent' : ''}`"
                            d="M60.5926 71.601L58.2726 53.0265C57.9439 50.3956 56.6653 47.9754 54.6772 46.221C52.6892 44.4666 50.1288 43.499 47.4774 43.5H39.4879C36.8377 43.5007 34.2791 44.4692 32.2926 46.2234C30.3061 47.9776 29.0285 50.3968 28.6999 53.0265L26.3763 71.601C26.2487 72.6215 26.3398 73.6574 26.6433 74.64C26.9469 75.6226 27.456 76.5294 28.137 77.3001C28.8179 78.0708 29.6549 78.6879 30.5926 79.1103C31.5303 79.5327 32.5471 79.7507 33.5755 79.75H53.397C54.4251 79.7502 55.4415 79.5318 56.3788 79.1092C57.316 78.6865 58.1527 78.0694 58.8332 77.2988C59.5137 76.5281 60.0225 75.6215 60.3258 74.6392C60.6292 73.6568 60.7201 72.6212 60.5926 71.601Z"
                            stroke="#535a7e"
                            stroke-width="3.625"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            :class="`${this.$route.path == '/candidates' ? 'active-path-talent' : ''}`"
                            d="M43.5 29C49.5061 29 54.375 24.1311 54.375 18.125C54.375 12.1189 49.5061 7.25 43.5 7.25C37.4939 7.25 32.625 12.1189 32.625 18.125C32.625 24.1311 37.4939 29 43.5 29Z"
                            stroke="#535a7e"
                            stroke-width="3.625"
                        />
                        <path
                            :class="`${this.$route.path == '/candidates' ? 'active-path-talent' : ''}`"
                            d="M14.5 39.875C18.5041 39.875 21.75 36.6291 21.75 32.625C21.75 28.6209 18.5041 25.375 14.5 25.375C10.4959 25.375 7.25 28.6209 7.25 32.625C7.25 36.6291 10.4959 39.875 14.5 39.875Z"
                            stroke="#535a7e"
                            stroke-width="3.625"
                        />
                        <path
                            :class="`${this.$route.path == '/candidates' ? 'active-path-talent' : ''}`"
                            d="M72.5 39.875C76.5041 39.875 79.75 36.6291 79.75 32.625C79.75 28.6209 76.5041 25.375 72.5 25.375C68.4959 25.375 65.25 28.6209 65.25 32.625C65.25 36.6291 68.4959 39.875 72.5 39.875Z"
                            stroke="#535a7e"
                            stroke-width="3.625"
                        />
                        <path
                            :class="`${this.$route.path == '/candidates' ? 'active-path-talent' : ''}`"
                            d="M14.493 50.75H13.3838C11.6676 50.7498 10.0069 51.3584 8.69731 52.4676C7.38768 53.5767 6.51399 55.1145 6.23167 56.8074L5.02455 64.0574C4.85132 65.0961 4.90645 66.1601 5.18611 67.1754C5.46577 68.1906 5.96325 69.1328 6.64393 69.9363C7.32462 70.7398 8.17218 71.3854 9.12766 71.8281C10.0831 72.2708 11.1236 72.5001 12.1767 72.5H25.368M72.4931 50.75H73.6023C75.3185 50.7498 76.9792 51.3584 78.2888 52.4676C79.5984 53.5767 80.4721 55.1145 80.7544 56.8074L81.9616 64.0574C82.1348 65.0961 82.0796 66.1601 81.8 67.1754C81.5203 68.1906 81.0229 69.1328 80.3422 69.9363C79.6615 70.7398 78.8139 71.3854 77.8584 71.8281C76.903 72.2708 75.8625 72.5001 74.8094 72.5H61.6181"
                            stroke="#535a7e"
                            stroke-width="3.625"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>

                    <span :class="{ hideText: !SidebarViewOpen }">{{ $t("Talents") }}</span>
                </router-link>
            </li>

            <li>
                <router-link
                    to="/payement"
                    @click="selectTab(4)"
                    class="hover"
                    :class="`w-full gap-4 p-2 text-[#7d8fb3] flex items-center font-semibold  text-lg ${this.$route.path == '/payement' ? 'active ' : ''}`"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" class="payouts-img" width="25" height="25" viewBox="0 0 112 80" fill="none">
                        <path
                            :class="`${this.$route.path == '/payement' ? 'active-path-payouts' : ''}`"
                            d="M105.5 0.8125H6.5C4.85897 0.8125 3.28516 1.46439 2.12478 2.62478C0.964396 3.78516 0.3125 5.35897 0.3125 7V73C0.3125 74.641 0.964396 76.2148 2.12478 77.3752C3.28516 78.5356 4.85897 79.1875 6.5 79.1875H105.5C107.141 79.1875 108.715 78.5356 109.875 77.3752C111.036 76.2148 111.688 74.641 111.688 73V7C111.688 5.35897 111.036 3.78516 109.875 2.62478C108.715 1.46439 107.141 0.8125 105.5 0.8125ZM6.5 4.9375H105.5C106.047 4.9375 106.572 5.1548 106.958 5.54159C107.345 5.92839 107.562 6.45299 107.562 7V21.4375H4.4375V7C4.4375 6.45299 4.6548 5.92839 5.04159 5.54159C5.42839 5.1548 5.95299 4.9375 6.5 4.9375ZM105.5 75.0625H6.5C5.95299 75.0625 5.42839 74.8452 5.04159 74.4584C4.6548 74.0716 4.4375 73.547 4.4375 73V25.5625H107.562V73C107.562 73.547 107.345 74.0716 106.958 74.4584C106.572 74.8452 106.047 75.0625 105.5 75.0625ZM95.1875 60.625C95.1875 61.172 94.9702 61.6966 94.5834 62.0834C94.1966 62.4702 93.672 62.6875 93.125 62.6875H76.625C76.078 62.6875 75.5534 62.4702 75.1666 62.0834C74.7798 61.6966 74.5625 61.172 74.5625 60.625C74.5625 60.078 74.7798 59.5534 75.1666 59.1666C75.5534 58.7798 76.078 58.5625 76.625 58.5625H93.125C93.672 58.5625 94.1966 58.7798 94.5834 59.1666C94.9702 59.5534 95.1875 60.078 95.1875 60.625ZM62.1875 60.625C62.1875 61.172 61.9702 61.6966 61.5834 62.0834C61.1966 62.4702 60.672 62.6875 60.125 62.6875H51.875C51.328 62.6875 50.8034 62.4702 50.4166 62.0834C50.0298 61.6966 49.8125 61.172 49.8125 60.625C49.8125 60.078 50.0298 59.5534 50.4166 59.1666C50.8034 58.7798 51.328 58.5625 51.875 58.5625H60.125C60.672 58.5625 61.1966 58.7798 61.5834 59.1666C61.9702 59.5534 62.1875 60.078 62.1875 60.625Z"
                            fill="#535a7e"
                        />
                    </svg>

                    <!-- <font-awesome-icon :icon="['fas', 'chart-line']" class="fa-icon" /> -->
                    <span :class="{ hideText: !SidebarViewOpen }">{{ $t("Payouts") }}</span>
                </router-link>
            </li>
            <li>
                <router-link
                    to="/archive"
                    @click="selectTab(5)"
                    class="hover"
                    :class="`w-full gap-4 p-2 text-[#7d8fb3] flex items-center font-semibold  text-lg ${
                        this.$route.path == '/Manage' || this.$route.path == '/refferal' || this.$route.path == '/archive' || this.$route.path == '/invoice' ? 'active' : ''
                    }`"
                >
                    <svg class="manage-img" width="20" height="20" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            :class="`${
                                this.$route.path == '/Manage' || this.$route.path == '/refferal' || this.$route.path == '/archive' || this.$route.path == '/invoice' ? 'active-path-manage' : ''
                            }`"
                            d="M4.375 13.2855V21.7001C4.375 24.7917 4.375 24.7917 7.29167 26.7605L15.3125 31.398C16.5229 32.098 18.4917 32.098 19.6875 31.398L27.7083 26.7605C30.625 24.7917 30.625 24.7917 30.625 21.7146V13.2855C30.625 10.2084 30.625 10.2084 27.7083 8.23965L19.6875 3.60215C18.4917 2.90215 16.5229 2.90215 15.3125 3.60215L7.29167 8.23965C4.375 10.2084 4.375 10.2084 4.375 13.2855Z"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            :class="`${
                                this.$route.path == '/Manage' || this.$route.path == '/refferal' || this.$route.path == '/archive' || this.$route.path == '/invoice' ? 'active-path-manage' : ''
                            }`"
                            d="M17.5 21.875C19.9162 21.875 21.875 19.9162 21.875 17.5C21.875 15.0838 19.9162 13.125 17.5 13.125C15.0838 13.125 13.125 15.0838 13.125 17.5C13.125 19.9162 15.0838 21.875 17.5 21.875Z"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>

                    <!-- <font-awesome-icon :icon="['fas', 'chart-line']" class="fa-icon" /> -->
                    <span :class="{ hideText: !SidebarViewOpen }">{{ $t("Manage") }}</span>
                </router-link>
            </li>

            <li class="hover group disabled">
                <router-link
                    :to="isDisabled ? '#' : '/calendar'"
                    @click="selectTab(6)"
                    :class="`w-[100%] h-[100%] gap-4 p-2 text-[#7d8fb3] flex items-center font-semibold relative text-lg ${this.$route.path == '/calendar' ? 'active ' : ''}`"
                >
                    <svg class="home-img" width="20" height="20" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            :class="`${this.$route.path == '/calendar' ? 'active-path-home' : ''}`"
                            d="M17.5 26.25V21.875"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            :class="`${this.$route.path == '/calendar' ? 'active-path-home' : ''}`"
                            d="M14.6855 4.11244L4.57927 12.2062C3.44177 13.1104 2.7126 15.0208 2.96052 16.4499L4.9001 28.0583C5.2501 30.1291 7.23344 31.8062 9.33344 31.8062H25.6668C27.7522 31.8062 29.7501 30.1145 30.1001 28.0583L32.0397 16.4499C32.273 15.0208 31.5439 13.1104 30.4209 12.2062L20.3147 4.12703C18.7543 2.87286 16.2314 2.87286 14.6855 4.11244Z"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <!-- <font-awesome-icon :icon="['far', 'clock']" class="fa-icon" /> -->
                    <span
                        :class="{
                            hideText: !SidebarViewOpen,
                        }"
                        >{{ $t("Schedule") }}</span
                    >
                </router-link>
            </li>
            <li></li>
        </ul>
        <UpgradeBtn @click="this.Store.toggleSubscriptions()" />
    </aside>

    <!------------------------------------------------------------------------------------------------------------------------>

    <aside v-if="this.Store.getisLogged == 'admin'" class="sidebar" :class="{ active_style: !SidebarViewOpen }">
        <div class="profile" v-show="SidebarViewOpen">
            <!-- <img loading="lazy"  decoding="async" :src="getImgUrl(this.Store?.companyLogo)" height="150" width="150" alt="user" /> -->
            <span class="w-28 h-28 mb-2 flex justify-center items-center rounded-full bg-NeonBlue text-white font-bold text-5xl shadow-[0_0_6px_1px_rgba(0,0,0,.15)] text-center">{{
                companyInitials
            }}</span>
            <div class="greeting">Hello {{ this.Store.admin_name }}</div>
            <!-- <div class="email">{{ userEmail || `GO.Platform@gmail.com` }}</div> -->
        </div>

        <ul class="nav-links" :class="{ centerIcons: !SidebarViewOpen }">
            <li>
                <router-link
                    to="/adminBlogs"
                    @click="selectTab(1)"
                    class="hover"
                    :class="`w-full gap-4 p-2 text-[#7d8fb3] flex items-center font-semibold  text-lg ${this.$route.path == '/adminBlogs' ? 'active ' : ''}`"
                >
                    <svg class="library-img" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            :class="`${this.$route.path == '/adminBlogs' ? 'active-path-library ' : ''}`"
                            d="M32.0827 24.4126V6.81055C32.0827 5.06055 30.6535 3.76263 28.9181 3.90847H28.8306C25.7681 4.17097 21.116 5.73138 18.5202 7.36472L18.2723 7.52513C17.8493 7.78763 17.1493 7.78763 16.7264 7.52513L16.3618 7.30638C13.766 5.68763 9.12851 4.1418 6.06602 3.89388C4.3306 3.74805 2.91602 5.06055 2.91602 6.79597V24.4126C2.91602 25.8126 4.05352 27.1251 5.45352 27.3001L5.87643 27.3585C9.04102 27.7814 13.9264 29.3856 16.7264 30.9168L16.7848 30.946C17.1785 31.1647 17.8056 31.1647 18.1848 30.946C20.9848 29.4001 25.8848 27.7814 29.0639 27.3585L29.5452 27.3001C30.9452 27.1251 32.0827 25.8126 32.0827 24.4126Z"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            :class="`${this.$route.path == '/adminBlogs' ? 'active-path-library ' : ''}`"
                            d="M17.5 8.00586V29.8809"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            :class="`${this.$route.path == '/adminBlogs' ? 'active-path-library ' : ''}`"
                            d="M11.3027 12.3809H8.02148"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            :class="`${this.$route.path == '/adminBlogs' ? 'active-path-library ' : ''}`"
                            d="M12.3965 16.7559H8.02148"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <!-- <font-awesome-icon :icon="['fas', 'chart-line']" class="fa-icon" /> -->

                    <span :class="{ hideText: !SidebarViewOpen }">{{ $t("Blogs") }}</span>
                </router-link>
            </li>
            <li>
                <router-link
                    to="/categories"
                    @click="selectTab(2)"
                    class="hover"
                    :class="`w-full gap-4 p-2 text-[#7d8fb3] flex items-center font-semibold  text-lg ${this.$route.path == '/categories' ? 'active ' : ''}`"
                >
                    <svg class="activity-img" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            :class="`${this.$route.path == '/categories' ? 'active-path-activity ' : ''}`"
                            d="M13.1243 32.0837H21.8743C29.166 32.0837 32.0827 29.167 32.0827 21.8753V13.1253C32.0827 5.83366 29.166 2.91699 21.8743 2.91699H13.1243C5.83268 2.91699 2.91602 5.83366 2.91602 13.1253V21.8753C2.91602 29.167 5.83268 32.0837 13.1243 32.0837Z"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            :class="`${this.$route.path == '/categories' ? 'active-path-activity ' : ''}`"
                            d="M10.6895 21.1316L14.1603 16.6254C14.6561 15.9837 15.5749 15.8671 16.2165 16.3629L18.8853 18.4629C19.527 18.9587 20.4457 18.8421 20.9415 18.215L24.3103 13.8691"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>

                    <!-- <font-awesome-icon :icon="['fas', 'chart-line']" class="fa-icon" /> -->
                    <span :class="{ hideText: !SidebarViewOpen }">{{ $t("Categories") }}</span>
                </router-link>
            </li>
            <li>
                <router-link
                    to="/assessments"
                    @click="selectTab(3)"
                    class="hover"
                    :class="`w-full gap-4 p-2 text-[#7d8fb3] flex items-center font-semibold  text-lg ${this.$route.path == '/assessments/add' ? 'active ' : ''}`"
                >
                    <svg class="activity-img" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            :class="`${this.$route.path == '/assessments/add' ? 'active-path-activity ' : ''}`"
                            d="M13.1243 32.0837H21.8743C29.166 32.0837 32.0827 29.167 32.0827 21.8753V13.1253C32.0827 5.83366 29.166 2.91699 21.8743 2.91699H13.1243C5.83268 2.91699 2.91602 5.83366 2.91602 13.1253V21.8753C2.91602 29.167 5.83268 32.0837 13.1243 32.0837Z"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            :class="`${this.$route.path == '/assessments/add' ? 'active-path-activity ' : ''}`"
                            d="M10.6895 21.1316L14.1603 16.6254C14.6561 15.9837 15.5749 15.8671 16.2165 16.3629L18.8853 18.4629C19.527 18.9587 20.4457 18.8421 20.9415 18.215L24.3103 13.8691"
                            stroke="#535a7e"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>

                    <!-- <font-awesome-icon :icon="['fas', 'chart-line']" class="fa-icon" /> -->
                    <span :class="{ hideText: !SidebarViewOpen }">{{ $t("Assessments") }}</span>
                </router-link>
            </li>

            <li>
                <router-link to="/companies" class="hover" :class="`w-full gap-4 p-2 text-[#7d8fb3] flex items-center font-semibold  text-lg ${this.$route.path == '/companies' ? 'active ' : ''}`">
                    <font-awesome-icon :icon="['far', 'building']" class="w-8 h-8" :class="`${this.$route.path == '/companies' ? 'active-path-activity ' : ''}`" />
                    <!-- <font-awesome-icon :icon="['fas', 'chart-line']" class="fa-icon" /> -->
                    <span :class="{ hideText: !SidebarViewOpen }">{{ $t("Companies") }}</span>
                </router-link>
            </li>

            <li>
                <router-link
                    to="/admin"
                    @click="selectTab(0)"
                    class="hover"
                    :class="`w-full gap-4 p-2 text-[#7d8fb3] flex items-center font-semibold  text-lg ${this.$route.path == '/admin' ? 'active ' : ''}`"
                >
                    <svg class="dashboard-img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_216_5517)">
                            <path
                                :class="`${selected[0] ? 'active-path-dashboard ' : ''}`"
                                d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z"
                                stroke="#535a7e"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_216_5517">
                                <rect width="24" height="24" fill="#535a7e" />
                            </clipPath>
                        </defs>
                    </svg>

                    <!-- <font-awesome-icon :icon="['fas', 'chart-line']" class="fa-icon" /> -->
                    <span :class="{ hideText: !SidebarViewOpen }">{{ $t("Dashboard") }}</span>
                </router-link>
            </li>
            <li>
                <router-link
                    to="/Job-Positions"
                    class="hover"
                    :class="`w-full gap-4 p-2 text-[#7d8fb3] flex items-center font-semibold  text-lg ${this.$route.path == '/Job-Positions' ? 'active ' : ''}`"
                >
                    <font-awesome-icon :icon="['far', 'building']" class="w-8 h-8" :class="`${this.$route.path == '/Job-Positions' ? 'active-path-activity ' : ''}`" />
                    <!-- <font-awesome-icon :icon="['fas', 'chart-line']" class="fa-icon" /> -->
                    <span :class="{ hideText: !SidebarViewOpen }">{{ $t("Job Positions") }}</span>
                </router-link>
            </li>
        </ul>
    </aside>
</template>

<script>
import UpgradeBtn from "./UpgradeBtn.vue";
import { useStore } from "../store/index";
import axios from "axios";
export default {
    name: "SidebarView",
    components: {
        UpgradeBtn,
    },
    data() {
        return {
            selected: [false, false, false, false, false, false, false],
            current: 0,
            isDisabled: true,
            showCompanies: false,
            company: "",
            selectedCompany: this.Store.company_name,
            companies: [],
        };
    },
    computed: {
        companyInitials() {
            return this.Store.getCompanyInitials;
        },
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    beforeRouteLeave(to, from, next) {
        if (this.Store.companyCredit < 150) {
            // User's credit is insufficient, show the subscriptions popup or handle it as needed.
            next(false); // Prevent navigation
            this.togglePlans();
        } else {
            // User's credit is sufficient, allow navigation to the library.
            next();
        }
    },
    props: {
        SidebarViewOpen: Boolean,
        togglePlans: Function,
        toggleSideBarView: Function,
    },
    methods: {
        getImgUrl(imgFileId) {
            if (imgFileId) {
                var image = `data:image/png;base64,${imgFileId}`;
                return image;
            }
            return require("@/assets/Images/user.png");
        },
        toggleShowCompanies() {
            this.showCompanies = !this.showCompanies;
        },
        selectTab(num) {
            if (num === this.current) {
                this.selected[num] = !this.selected[num];
            } else {
                this.selected[this.current] = false;
                this.selected[num] = true;
                this.current = num;
            }
        },
        switchCompany(companyName) {
            this.Store.isLoading = true;
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://server.go-platform.com/company/switchCompany",
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    company_name: companyName,
                },
                withCredentials: true,
            };
            axios
                .request(config)
                .then((response) => {
                    this.$router.push(`/dashboard?company_name=${response.data.company_name}`);
                    setTimeout(() => {
                        this.$router.go("");
                    }, 300);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.Store.userLogged();
        axios
            .get("https://server.go-platform.com/users/companies", {
                withCredentials: true,
            })
            .then((res) => {
                this.companies = res.data.companies;
            })
            .catch((err) => {
                console.log(err);
            });
    },
};
</script>

<style scoped lang="scss">
.soon-text {
    clip-path: polygon(0 0, calc(100% - 198px) 0, 100% 198px, 100% 100%, 198px 100%, 0 calc(100% - 198px));
}

.hover {
    svg {
        // margin-left: 5%;
        // transform: translateX(-50%);
        aspect-ratio: 3/2;
        object-fit: contain;
    }
}

.dashboard-img {
    width: 25px;
    height: 25px;
}

.payouts-img {
    width: 24px;
    height: 25px;
}

.sidebar {
    background-color: #fff;
    width: 18%;
    height: 100%;
    transition: all 0.4s ease-in-out;
    overflow-x: hidden;
    position: fixed;

    top: 0;
    z-index: 9;
    margin-right: 10px;
}

.active_style {
    width: 5%;
}

.centerIcons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.welcome {
    width: fit-content;
    padding: 24px;
    padding-bottom: 48px;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 19px;
    color: #0f172a;
    border-bottom: 1px solid #f4f7fe;
    transition: all 0.4s ease-in-out;
}

ul {
    list-style: none;
    padding: 0;
}

a {
    text-decoration: none;
    font-family: "Roboto";
    // font-weight: 500;
    color: #7d8fb3;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.current_tab {
    color: #2196f3;

    &::before {
        content: "";
        position: absolute;
        left: -3rem;
        height: 100%;
        width: 2px;
        border-radius: 25px;
        background-color: #2196f3;
    }
}

.greeting {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 16px;
    color: #6b7a99;
}

.email {
    font-family: "Roboto";
    font-style: normal;
    font-size: 14px;
    color: #adb8cc;
}

.nav-icon {
    font-size: 28px;
}

.active .nav-icon {
    color: #2196f3;
}

.active-img {
}

.top-buttons {
    // display: flex;
    // justify-content: space-between;
    padding: 0 1rem;
    margin-top: 1rem;
}

.profile {
    display: flex;
    justify-items: center;
    margin: 16px;
    align-items: center;
    flex-direction: column;
}

li {
    margin: 0px 16px;
    // margin-bottom: 8px;
    padding: 0 8px;
}

img {
    // margin-right: 8px;
    // width: 28px;
    margin-bottom: 8px;
    max-width: fit-content;
}

li a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: baseline;
    gap: 1rem;
    margin: 0;
    padding: 3px;
}

.fa-icon {
    font-size: 28px;
    font-weight: 800;
    /* color: #535a7e; */
}

.active:hover {
    background: rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.05);
}

.nav-links a {
    margin-bottom: 3px;
    color: #535a7e;
}

.nav-links a:active {
    background: #2196f3;

    color: #fff;
    border-radius: 5px;
}

.nav-links a:hover {
    background: #2196f3;

    color: #fff;
    border-radius: 5px;
}

.nav-links a:hover .dashboard-img path {
    stroke: #fff !important;
}

.active-path-dashboard {
    stroke: #fff !important;
}

.nav-links a:hover .library-img path {
    stroke: #fff !important;
}

.active-path-library {
    stroke: #fff !important;
}

.nav-links a:hover .activity-img path {
    stroke: #fff !important;
}

.active-path-activity {
    stroke: #fff !important;
}

.nav-links a:hover .talent-img path {
    stroke: #fff !important;
}

.active-path-talent {
    stroke: #fff !important;
}

.nav-links a:hover .payouts-img path {
    fill: #fff !important;
}

.active-path-payouts {
    fill: #fff !important;
}

.nav-links a:hover .manage-img path {
    stroke: #fff !important;
}

.active-path-manage {
    stroke: #fff !important;
}

.nav-links a .home-img path {
    stroke: #7d8fb380 !important;
}

.active-path-home {
    stroke: #fff !important;
}

.swCompany {
    width: max-content;

    span {
        cursor: pointer;
        background-color: #fff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

        &:hover {
            color: #fff;
            background-color: #2196f3;
        }
    }
}

.disabled {
    > :last-child {
        color: #7d8fb380;
        background: none;

        &:hover {
            cursor: not-allowed !important;
            background-color: none !important;
            color: #fff;
        }

        // > :nth-child(2) {
        //     text-decoration: line-through;
        //     text-decoration-thickness: 2px;
        // }
    }

    &:hover {
        cursor: not-allowed !important;

        > :last-child {
            color: #7d8fb380;
            background: none;

            svg {
                // background-color: red;
                color: red;
                // fill: red;
                stroke: red !important;
            }
        }
    }
}

.nav-btn {
    padding-top: 2px;

    > :first-child {
        font-size: 20px;

        &:hover {
            cursor: pointer;
        }
    }
}

.logo-btn:hover {
    border: 2px solid #2196f3;
}

.logo-icon {
    color: #2196f3;
}

select {
    width: 70%;
    background: transparent;
    border: 2px solid #adb8cc;
    border-radius: 10px;
}

.navTitle {
    font-size: 13px;
    font-weight: 500;
    color: #7d8fb3;
    margin-bottom: 10px;
    display: block;
}

.hideText {
    display: none;
}

.active {
    background: #2196f3;
    border-radius: 5px;
    color: #fff;

    /* transition: all .4s ease-in-out; */
}

.active span {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
}

.active img {
    color: #fff !important;
    font-weight: 500;
    font-size: 20px;
}
</style>
